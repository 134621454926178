export interface IState {
	menu: IMenu
	endpoints: {
		getChildren: string
		searchPageUri: string
	}
	logo: string
	secondaryLogo: string
	logoAltText: string
	secondaryLogoAltText: string
	topLinks: ITopLinks
	showSearch: boolean
	menuOpen: boolean
	isStartPage: boolean
	startPageUri: string
	isMainStartPage: boolean
	mainStartPageUri: string
	heroImageHtml: string
	mobileMenuOpen: boolean
	showSearchMobile: boolean
}

export interface IMenu {
	mainItems: IMenuMainItem[]
}

export interface ITopLinks {
	contact: string
	english: string
	lots: string
}

export interface IMenuItem {
	name: string
	uri: string
	id: string
	isCurrent: boolean
	isExpanded: boolean
	hasChildren: boolean
	level: number
	children: IMenuItem[]
}

export interface IMenuMainItem {
	name: string
	id: string
	isExpanded: boolean
	menuItems: IMenuItem[]
}

const deafultState: IState = {
	menu: {
		mainItems: []
	},
	endpoints: {
		getChildren: '',
		searchPageUri: ''
	},
	logo: '',
	secondaryLogo: '',
	logoAltText: '',
	secondaryLogoAltText: '',
	topLinks: {
		contact: '',
		english: '',
		lots: '',
	},
	showSearch: false,
	menuOpen: false,
	isStartPage: false,
	startPageUri: '',
	isMainStartPage: false,
	mainStartPageUri: '',
	heroImageHtml: '',
	mobileMenuOpen: false,
	showSearchMobile: false
}

export default deafultState;
