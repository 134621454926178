import { IMenuItem, IState } from "./state";
import { ActionTree, Store, ActionContext } from 'vuex';
import * as api from '../api';

type PayloadType = 'initApp' | 'setState' | 'getChildren' | 'toggleExpanded' | 'toggleMenu' | 'closeExpanded'
export type ActionPayload<ActionData> = {
	type: PayloadType,
	payload: ActionData
}
export interface IGetChildrenPayload {
	id: string
	level: number
}
export interface IAddMenuItemsPayload {
	menuItem: IMenuItem,
	items: IMenuItem[]
}
export interface IToggleMenuItem {
	menuItem: IMenuItem,
	isDesktop: boolean
}

type ActionHandler<ActionData> = (this: Store<IState>, injectee: ActionContext<IState, IState>, payload: ActionPayload<ActionData>) => any;
type ActionCreator<ActionData> = (data: ActionData) => ActionPayload<ActionData>;


// Action creators
export const initApp: ActionCreator<IState> = (state) => ({
	type: 'initApp',
	payload: state
})
export const getChildren: ActionCreator<IGetChildrenPayload> = payload => ({
	type: 'getChildren',
	payload: payload

})
export const toggleExpanded: ActionCreator<IMenuItem> = payload => ({
	type: 'toggleExpanded',
	payload: payload

})
export const closeExpanded: ActionCreator<IState> = payload => ({
	type: 'closeExpanded',
	payload: payload

})
// Action handlers
const initAppAction: ActionHandler<IState> = ({ commit }, { payload }) => {
	commit({
		type: 'setState',
		payload
	})
}
const getChildrenAction: ActionHandler<IMenuItem> = ({ commit, state }, { payload }) => {
	api.getMenuItems(state.endpoints.getChildren, {id: payload.id, level: payload.level})
		.then(res => {
			commit({
				type: 'addMenuItems',
				payload: {menuItem: payload, items: res}
			})
		})
}
const closeExpandedAction: ActionHandler<IState> = ({ commit, state }, { payload }) => {
	for (const property in state.menu) {
		if((state.menu as any)[property].isExpanded === true) {
			commit({
				type: 'toggleExpanded',
				payload: (state.menu as any)[property]
			})
			break
		}
	}
}
const toggleExpandedAction: ActionHandler<IToggleMenuItem> = ({ commit, dispatch, state }, { payload }) => {
	if(payload.isDesktop) {
		for (const property in state.menu) {
			if((state.menu as any)[property].isExpanded === true && (state.menu as any)[property] != payload.menuItem && !(state.menu as any)[property].children.includes(payload)) {
				commit({
					type: 'toggleExpanded',
					payload: (state.menu as any)[property]
				})
				break
			}
		}
	}
	commit({
		type: 'toggleExpanded',
		payload: payload.menuItem
	})
	if(payload.menuItem.children.length < 1) {
		dispatch(getChildren(payload.menuItem))
	}
}
const toggleMenuAction: ActionHandler<boolean> = ({ commit, state }) => {
	for (const property in state.menu) {
		if((state.menu as any)[property].isExpanded === true) {
			commit({
				type: 'toggleExpanded',
				payload: (state.menu as any)[property]
			})
			break
		}
	}
}

const actions: ActionTree<IState, IState> = {
	initApp: initAppAction,
	getChildren: getChildrenAction,
	toggleExpanded: toggleExpandedAction,
	toggleMenu: toggleMenuAction,
	closeExpanded: closeExpandedAction,
}

export default actions;
