
import Vue from 'vue'
import { ITopLinks } from '../store/state'

interface TopHeaderProps {
	topLinks: Object
	logo: string
    secondaryLogo: string
    logoAltText: string
    secondaryLogoAltText: string
	startPageUri: string
	isStartPage: boolean
	isMainStartPage: boolean
	mainStartPageUri: string
}

interface TopHeaderComputed {
    showSearch: boolean
    showSearchMobile: boolean
    mobileMenuOpen: boolean
    searchPageUri: string
}

interface TopHeaderMethods {
    toggleSearch(mobile: boolean): void
    toggleMobileMenu(): void
}

export default Vue.extend<{}, TopHeaderMethods, TopHeaderComputed, TopHeaderProps>({
	name: 'TopHeader',
	props: {
		logo: String,
        secondaryLogo: String,
        logoAltText: String,
        secondaryLogoAltText: String,
        topLinks: Object,
        startPageUri: String,
        isStartPage: Boolean,
        isMainStartPage: Boolean,
        mainStartPageUri: String
	},
    computed: {
        showSearch() {
            return this.$store.getters.showSearch
        },
        showSearchMobile() {
            return this.$store.getters.showSearchMobile
        },
        mobileMenuOpen() {
            return this.$store.getters.mobileMenuOpen
        },
        searchPageUri() {
            return this.$store.getters.searchPageUri
        }
    },
    methods: {
        toggleSearch(mobile) {
            this.$store.commit({
                type: 'toggleSearch',
                payload: mobile
            })
        },
        toggleMobileMenu() {
            this.$store.commit({
                type: 'toggleMobileMenu',
                payload: undefined
            })
        }
    },
    mounted () {
        window.addEventListener('click', (e) => {
			const appRefMobile = this.$refs.searchMobile as HTMLElement;
			const target = e.target as HTMLElement;
            if(appRefMobile) {
                if(appRefMobile.contains(target)) {
                    
                } else {
                    if(this.showSearchMobile && !target.hasAttribute('data-ignore-close-search')) {
                        this.toggleSearch(true)
                    }
                }
            }
		})
    }
})
