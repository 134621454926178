import { Getter } from "vuex";
import { IState } from './state';

const topLinks: Getter<IState, IState> = (state) => state.topLinks;
const logo: Getter<IState, IState> = (state) => state.logo;
const secondaryLogo: Getter<IState, IState> = (state) => state.secondaryLogo;
const logoAltText: Getter<IState, IState> = (state) => state.logoAltText;
const secondaryLogoAltText: Getter<IState, IState> = (state) => state.secondaryLogoAltText;
const menu: Getter<IState, IState> = (state) => state.menu;
const showSearch: Getter<IState, IState> = (state) => state.showSearch;
const showSearchMobile: Getter<IState, IState> = (state) => state.showSearchMobile;
const isStartPage: Getter<IState, IState> = (state) => state.isStartPage;
const startPageUri: Getter<IState, IState> = (state) => state.startPageUri;
const isMainStartPage: Getter<IState, IState> = (state) => state.isMainStartPage;
const mainStartPageUri: Getter<IState, IState> = (state) => state.mainStartPageUri;
const heroImageHtml: Getter<IState, IState> = (state) => state.heroImageHtml;
const mobileMenuOpen: Getter<IState, IState> = (state) => state.mobileMenuOpen;
const searchPageUri: Getter<IState, IState> = (state) => state.endpoints.searchPageUri;

export default {
	topLinks,
	logo,
	secondaryLogo,
	logoAltText,
	secondaryLogoAltText,
	menu,
	showSearch,
	showSearchMobile,
	isStartPage,
	startPageUri,
	isMainStartPage,
	mainStartPageUri,
	heroImageHtml,
	mobileMenuOpen,
	searchPageUri
}
