
import Vue from 'vue'
import TopHeader from './components/TopHeader.vue';
import Menu from './components/Menu.vue';

// Interfaces for component typechecking!
interface AppData {

}

interface AppMethods {

}

interface AppComputed {
	topLinks: Object
	logo: string
	secondaryLogo: string
	logoAltText: string
	secondaryLogoAltText: string
	startPageUri: string
	isStartPage: boolean
	isMainStartPage: boolean
	mainStartPageUri: string
	heroImageHtml: string
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	computed: {
		topLinks() {
			return this.$store.getters.topLinks;
		},
		logo() {
			return this.$store.getters.logo;
		},
		secondaryLogo() {
			return this.$store.getters.secondaryLogo;
		},
		logoAltText() {
			return this.$store.getters.logoAltText;
		},
		secondaryLogoAltText() {
			return this.$store.getters.secondaryLogoAltText;
		},
		isStartPage() {
			return this.$store.getters.isStartPage;
		},
		startPageUri() {
			return this.$store.getters.startPageUri;
		},
		isMainStartPage() {
			return this.$store.getters.isMainStartPage;
		},
		mainStartPageUri() {
			return this.$store.getters.mainStartPageUri;
		},
		heroImageHtml() {
			return this.$store.getters.heroImageHtml;
		}
	},
	components: {
		TopHeader,
		Menu
	}
})
