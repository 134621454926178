
import Vue from 'vue'
import { IMenuItem, IMenu } from '../store/state'

interface MenuItemProps {
	item: IMenuItem
}

interface MenuItemComputed {
    menu: IMenu 
}

interface MenuItemMethods {
    toggleExpanded(item: IMenuItem): void
    isDesktop(): boolean
}

export default Vue.extend<{}, MenuItemMethods, MenuItemComputed, MenuItemProps>({
	name: 'MenuItem',
    props: {
		item: {},
	},
    computed: {
        menu() {
            return this.$store.getters.menu
        },
    },
    methods: {
        toggleExpanded(item) {
            this.$store.dispatch({
                type: 'toggleExpanded',
                payload: {
                    menuItem: item,
                    isDesktop: this.isDesktop()
                }
            })
        },
        isDesktop() {
            return document.body.clientWidth > 1022
        }
    }
})
