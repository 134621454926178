import { MutationTree, Mutation } from 'vuex';
import { IState, IMenuItem } from './state';
import { ActionPayload, IAddMenuItemsPayload } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}

const toggleSearchMutation: MutationHandler<boolean> = (state, { payload }) => {
	if(payload === true) {
		state.showSearchMobile = !state.showSearchMobile;
	} else {
		state.showSearch = !state.showSearch;
	}
}
const toggleMobileMenuMutation: MutationHandler<IState> = (state, { payload }) => {
	state.mobileMenuOpen = !state.mobileMenuOpen;
}
const addMenuItemsMutation: MutationHandler<IAddMenuItemsPayload> = (state, { payload }) => {
	if(payload.menuItem) {
		Object.assign(payload.menuItem, { children: payload.items})
	}
}
const toggleExpandedMutation: MutationHandler<IMenuItem> = (state, { payload }) => {
	Object.assign(payload, {isExpanded: !payload.isExpanded})
}

const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	toggleSearch: toggleSearchMutation,
	addMenuItems: addMenuItemsMutation,
	toggleExpanded: toggleExpandedMutation,
	toggleMobileMenu: toggleMobileMenuMutation
}

export default mutations
