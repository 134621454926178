
import Vue from 'vue'
import { IMenu, IMenuItem } from '../store/state'
import MenuItem from '../components/MenuItem.vue';//hje

interface MenuMethods {
    toggleExpanded(item: IMenuItem): void
    closeMenu(): void
    toggleSearch(mobile: boolean): void
    isDesktop(): boolean
    closeExpanded(): void
}

interface MenuProps {

}

interface MenuComputed {
	menu: IMenu
    showSearch: boolean
    mobileMenuOpen: boolean
    searchPageUri: string
}

export default Vue.extend<{}, MenuMethods, MenuComputed, MenuProps>({
	name: 'Menu',
    computed: {
        menu() {
            return this.$store.getters.menu
        },
        showSearch() {
            return this.$store.getters.showSearch
        },
        mobileMenuOpen() {
            return this.$store.getters.mobileMenuOpen
        },
        searchPageUri() {
            return this.$store.getters.searchPageUri
        }
    },
    methods: {
        toggleExpanded(item) {
            const html = document.documentElement;
            if(item.isExpanded) {
                html.removeAttribute('menu-open');
            } else {
                html.setAttribute('menu-open', '');
            }
            this.$store.dispatch({
                type: 'toggleExpanded',
                payload: { 
                    menuItem: item,
                    isDesktop: this.isDesktop()
                }
            })
        },
        closeMenu() {
			this.$store.dispatch({
                type: 'toggleMenu',
                payload: false
            })
            const html = document.documentElement;
            html.removeAttribute('menu-open');
		},
        toggleSearch(mobile) {
            this.$store.commit({
                type: 'toggleSearch',
                payload: mobile
            })
        },
        isDesktop() {
            return document.body.clientWidth > 1022
        },
        closeExpanded() {
            this.$store.dispatch({
                type: 'closeExpanded',
                payload: undefined
            })
        }
    },
    mounted() {
		window.addEventListener('click', (e) => {
			const appRef = this.$refs.menu as HTMLElement;
			const target = e.target as HTMLElement;
            const targetControlls = target.getAttribute('aria-controls')
            if(appRef && !target.hasAttribute('data-ignore-close')) {
                let contains = false;

                for (const property in appRef) {
                    const id = (appRef as any)[property].getAttribute('id')
                    if(targetControlls === id || (appRef as any)[property].contains(target)) { 
                        contains = true;
                        break
                    }
                }
                if(!contains) {
                    this.closeMenu()
                }
            }
		})
        window.addEventListener('click', (e) => {
			const appRef = this.$refs.search as HTMLElement;
			const target = e.target as HTMLElement;
            if(appRef) {
                if(appRef.contains(target)) {

                } else {
                    if(this.showSearch && !target.hasAttribute('data-ignore-close')) {
                        this.toggleSearch(false)
                    }
                }
            }
		})
        if(this.isDesktop()) {
            this.closeExpanded()
        }
	},
    components: {
        MenuItem
    }
})
